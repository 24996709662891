<!--
 * @Description:订单申诉 orderComplain
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 08:56:10
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单申诉"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-form @submit="onSubmit"
              class="main">
      <van-row class="style">订单编号</van-row>
      <van-row class="style">{{orderList.orderSequence}}</van-row>
      <van-row class="style">申诉类型</van-row>
      <van-field readonly
                 clickable
                 name="picker"
                 :model="form.complaintType"
                 :value="value"
                 placeholder="请选择"
                 @click="showPicker = true" />
      <van-popup v-model="showPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="complaintTypeList"
                    @confirm="onConfirm"
                    @cancel="showPicker = false" />
      </van-popup>
      <van-row class="style">申诉描述</van-row>
      <van-cell-group class="describe">
        <van-field v-model="form.complaintDesc"
                   rows='5'
                   type="textarea"
                   placeholder="请详细说明申诉内容，处理将会更快捷~" />
      </van-cell-group>
      <van-row class="style">申诉结果我们将以短信或消息推送的形式发送给您请关注消息管理功能</van-row>
      <van-button class="button"
                  block
                  type="info"
                  :disabled="disabled"
                  native-type="submit">提 交</van-button>
    </van-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { Toast, Dialog } from 'vant'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      orderList: {}, // 路由参数
      form: {}, // 上传表单
      complaintTypeList: [], // 申诉类型列表
      complaintType: '', // 反馈类型编码
      value: '', // 反馈类型
      showPicker: false, // 选择弹出框
      moneyPay: '', // 实收金额
      disabled: false // 禁用开关
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.orderList = this.$route.query
    if (this.$route.query.paymentSequence) {
      this.value = '费用有误'
      this.complaintType = 0
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getComplaintState()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取申诉类型下拉框
    getComplaintState () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABA91DBUIUIUIU'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        res.resultEntity.forEach(item => {
          this.complaintTypeList.push(item.name)
        })
      })
    },
    // 表单提交
    onSubmit () {
      this.disabled = true
      if (this.orderList.moneyPay === undefined) {
        this.moneyPay = 0
      } else {
        this.moneyPay = this.orderList.moneyPay
      }
      const info = {
        orderId: this.$route.query.orderSequence, // 订单编号
        appealReason: this.form.complaintDesc, // 申请理由
        appealType: this.complaintType, // 申请类型
        paymentSequence: this.$route.query.paymentSequence
      }
      if (this.complaintType === undefined) {
        Toast('请选择申诉类型')
      } else if (!this.form.complaintDesc) {
        Toast('请填写描述信息')
      } else {
        Dialog.confirm({
          message: '确认提交'
        }).then(() => {
          this.$orderComplain.appeal(info).then(() => {
            Toast('申诉提交成功')
            this.$router.go(-1)
          })
        })
      }
      setTimeout(() => {
        this.disabled = false
      }, 1000)
    },
    // 选择框确认
    onConfirm (value, index) {
      this.value = value
      this.showPicker = false
      this.complaintType = index
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: PingFang-SC;
  .main {
    background-color: #fff;
    height: calc(100% - 64px - 20px);
    width: 100%;
  }
  // .main > div {
  //   padding: 12px 3.5% 0 3.5%;
  // }
}
.style {
  width: 92%;
  padding: 12px 4% 0 4%;
  height: 30px;
  line-height: 30px;
}
//描述
.describe {
  margin-top: 10px;
  height: 140px;
}
.button {
  width: 93.6%;
  margin: 30px 3.2% 0;
  border-radius: 5px;
}
</style>
